import React, {useState} from 'react';
import {Alert, Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {useParams} from "react-router-dom";
import { FIND_PROJECT, GET_PROJECT_VERSION} from "../../GraphQl/query";
import {useMutation, useQuery} from "@apollo/client";
import {CREATE_PROJECT_VERSION, UPDATE_PROJECT_VERSION} from "../../GraphQl/mutation";
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { addDays, addMonths , format, isBefore, subDays } from 'date-fns';
const ProjectVersionModal = ({
                          modalState,
                          toggleModal,
                          translate,
                          list
                      }) => {

    const {projectId} = useParams();
    const [projectVersion, setProjectVersion] = useState({});
    const [disabled, setDisabled] = useState(true);
    const [isUpdate, setIsUpdateMutationType] = useState(false);
  
  
    // Gestione Errori
    
    const [modal, setModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    
    const toggle = () => {
      
      setModal((prevState) => !prevState);
      
    }
  
  
  const getDefaultStartDate = (pVers) => {
    
        // returns the furthest point in time (latest end date) represented within the objects.
        // Once the object with the latest endPeriodDate is identified,
        // the code calculates the date that comes one day after that latest end date.
    
        // CIOÈ A DIRE LA PROSSIMA RENDICONTAZIONE PARTE DA UN GIORNO DOPO QUELLA PIU IN LA 
        // DELLE PRECEDENTI OVVERO QUELLE CHE APPAIONO NELLA LISTA
    
        if((!list || list.length===0) && pVers.project && pVers.project.startingDate){

            return new Date(pVers.project.startingDate);//parse(projectVersion.project.startingDate, 'yyyy-MM-dd HH:mm:ss', new Date());
        }else if(list.length>0){
            const lastInsertDate = list.reduce(function(prev, current) {
                return (isBefore(new Date(prev.endPeriodDate), new Date(current.endPeriodDate))) ? prev : current
            }) //returns object
            return addDays(new Date(lastInsertDate.endPeriodDate), 1);
        }
        return null;
    }
  
    const getDefaultEndDate = (pVers) => {
      
      // This function prioritizes using the project's extension date (if available) 
      // for final versions and adds one month to it as the default end date.
      // It offers alternative options based on the presence of endPeriodDate or a project ending date.
      // If no relevant information is found, it defaults to the current date.
      
      // PERL LE INTERMEDIE IN GENERALE VIENE SCELTA LA DATA PREVISTA DI FINE PROGETTO
      
      if(pVers.versionType === 'FINAL' && pVers.project && pVers.project.endingDate){
        const endDate = pVers.project.extensionDate ? pVers.project.extensionDate : pVers.project.endingDate;
        return addMonths(new Date(endDate), 1); // 1 mesi dopo la fine del progetto
      }
      else if( pVers.endPeriodDate ){
        return new Date(pVers.endPeriodDate);
      }else if(pVers.project && pVers.project.endingDate){
        return new Date(pVers.project.endingDate);
      }
      
      return new Date();
    }
  
    const getMinStartDate = () => {
        if((!list || list.length===0) && projectVersion.project && projectVersion.project.startingDate){

            return new Date(projectVersion.project.startingDate);//parse(projectVersion.project.startingDate, 'yyyy-MM-dd HH:mm:ss', new Date());
        }else if(list.length>0){
            const lastInsertDate = list.reduce(function(prev, current) {
                return (isBefore(new Date(prev.endPeriodDate), new Date(current.endPeriodDate))) ? prev : current
            }) //returns object
            return new Date(lastInsertDate.endPeriodDate);
        }
        return null;
    }
    const getMaxStartDate = () => {
        if( projectVersion.endPeriodDate ){
            return subDays(new Date(projectVersion.endPeriodDate),1);
        }else if(projectVersion.project && projectVersion.project.endingDate){

            return subDays(new Date(projectVersion.project.endingDate),1);
        }
        return null;
    }
    
    const getMinEndDate = () => {
		let minEndDate;

        if (projectVersion.startPeriodDate)
            minEndDate = addDays(new Date(projectVersion.startPeriodDate), 1);
        else if (projectVersion.project && projectVersion.project.startingDate)
            minEndDate = new Date(projectVersion.project.endingDate);
        else
        	minEndDate = null;

		return minEndDate;
    }

    const getMaxEndDate = () => {
		let maxEndDate;
		let dateArray;

		dateArray = [];
		if (projectVersion.endPeriodDate)
			dateArray.push(addDays(new Date(projectVersion.endPeriodDate), 1));

		if (projectVersion.project) {
			if (projectVersion.project.extensionDate)
				dateArray.push(subDays(new Date(projectVersion.project.extensionDate), 1));

			if (projectVersion.project.endingDate)
				dateArray.push(subDays(new Date(projectVersion.project.endingDate), 1));
		}

		if (dateArray.length > 0)
			maxEndDate = Math.max(...dateArray);
		else
			maxEndDate = null;
		
        return maxEndDate;
    }


    const {loading: loadingProject, data: dataProject, error: errorProject} = useQuery(FIND_PROJECT, {
        variables: { projectId },
    });

    // React.useEffect(() => {
    //     if (values) {
    //         // const projectVersion = data.result && data.result.length>0 ? data.result[0]:{};
    //         if(values && values.id){
    //             setIsUpdateMutationType(!!values);
    //         }
    //     }
        
    //     setProjectVersion(values?values:{})
    // }, [values]);

    React.useEffect(() => {
        if (dataProject) {
          
           
          
            const project = dataProject.result ? dataProject.result:{};
            var data = {...projectVersion};
            data.project = project;
            // data.active = false;
            data.active = true; // Settato Ad Attivo Per Default E Poi Nascosto
            data.startPeriodDate = getDefaultStartDate(data);
            data.endPeriodDate = getDefaultEndDate(data);
            data.projectId = project.id;
            setProjectVersion(data);
        }
    }, [dataProject, errorProject, loadingProject]);

    const getMutation = () =>{
        return isUpdate?UPDATE_PROJECT_VERSION:CREATE_PROJECT_VERSION
    };


    const [saveProjectVersion] = useMutation(getMutation(), {
        onCompleted(data) {
        }
    });
    const getProjectVersionDataToSave = () => {
        const end = getDefaultEndDate(projectVersion);
        const data = {
            active: projectVersion.active,
            projectId: projectVersion.project.id,
            description: projectVersion.description,
            endPeriodDate: format(end, 'yyyy-MM-dd') + ' 12:00:00',
            startPeriodDate: format(projectVersion.startPeriodDate, 'yyyy-MM-dd') + ' 12:00:00',
            versionType: projectVersion.versionType,
        }
        return data;
    }
    
    const setDate = (fieldName) => {
        return date => {
            // console.log(fieldName);
            var data = {...projectVersion};
            data[fieldName] = date
            setProjectVersion(data);
        }
    };
    
    
    var hasFinal = list.find(d => d.versionType === "FINAL");


    if(!projectVersion || !dataProject) return null;
  
    return (
        <div>
            <Button onClick={() => {
            
              setDisabled(true);
              toggleModal(true)}
            
            }>{"Inserimento"}</Button>
            <Modal isOpen={modalState} toggle={() => toggleModal(false)}>
                <ModalHeader>{translate("manageProjectVersion")}</ModalHeader>
                {hasFinal && <Alert color="red">{translate("Esiste già una rendicontazione finale, non ne puoi aggiungere altre")}</Alert>}
                {!hasFinal &&<><ModalBody>
                   
                    
                    <Label className='pt-2'><strong>{translate("project")}</strong></Label>
                    <Input 
                        type="text" 
                        placeholder={translate("project")} 
                        required 
                        defaultValue={projectVersion && projectVersion.project ? projectVersion.project.code:""} 
                        disabled/>
                  
                    { false &&
                    <FormGroup check className='pt-4'>
                        <Input type="checkbox"  
                        checked={projectVersion.active || false}
              
                        onChange={(e) => {  setProjectVersion({...projectVersion, active: !projectVersion.active});}}/>
                        <Label className='ps-2' check >
                             Attivo
                        </Label>
                    </FormGroup>
                    }
                  
                    <Label className='pt-2'><strong>{translate("description")}</strong></Label>
                    <Input 
                        type="description" 
                        placeholder={translate("description")} 
                        required 
                        defaultValue={projectVersion?projectVersion.description:""} 
                        onBlur={(e) => setProjectVersion({...projectVersion, description: e.target.value})}/>
 
                   

                    <Label className='pt-2'><strong>{translate("versionType")}</strong></Label>
                  
                    <Input
                        name="versionType"
                        type="select"
                        onChange={
                            (e) => {
                              
                              
                              if (e.target.value !== "FINAL" && e.target.value !== "INTERIM") {
                              
                                  setDisabled(true);
                                
                              } else {
                                
                                  setDisabled(false);
                                
                              }
                              
                              // let prev = {...projectVersion};
                            
                              
                              setProjectVersion({
                                ...projectVersion, versionType: e.target.value, 
                                startPeriodDate: getDefaultStartDate(projectVersion), 
                                endPeriodDate: getDefaultEndDate(projectVersion)
                            })
                              
                            // let post = {...projectVersion};
                        
                            
                            }
                          
                            }>
                        <option value="">
                            {translate("selectVerionType")}
                        </option>
                        <option value="FINAL">
                            {translate("FINAL")}
                        </option>
                        {projectVersion.project && projectVersion.project.structure && projectVersion.project.structure.hasInterim && 
                        <option value="INTERIM">
                            {translate("INTERIM")}
                        </option>}
                    </Input>
                    {projectVersion.versionType === "INTERIM" &&

                        <>
                            <Label className='pt-2'><strong>{translate("startPeriodDate")}</strong></Label>
                            <Col xs={12} className='ps-0'>
                                <DatePicker showIcon
                                    selected={projectVersion.startPeriodDate}
                                    dateFormat="dd-MM-yyyy"
                                    onChange={setDate("startPeriodDate")}
                                    minDate={getMinStartDate()}
                                    maxDate={getMaxStartDate()}
                                />
                                {/*{getMinStartDate().toDateString()} {" - "} {getMaxStartDate().toDateString()}*/}
                            </Col>
                          
                            <Label className='pt-2'><strong>{translate("endPeriodDate")}</strong></Label>
                            <Col xs={12} className='ps-0'>
                                <DatePicker showIcon
                                    selected={projectVersion.endPeriodDate}
                                    dateFormat="dd-MM-yyyy"
                                    onChange={setDate("endPeriodDate")}
                                    minDate={getMinEndDate()}
                                    maxDate={getMaxEndDate()}        
                                />
                                {/*{getMinEndDate().toDateString()} {" - "} {getMaxEndDate().toDateString()}*/}
                            </Col>
                          
                          
                          
                        </>
                     }
                   
                </ModalBody>
                  
                <ModalFooter>
                  
                  {/* <Button disabled={!ROLES_TO_SEE_STATUS.includes(projectVersionRole)} onClick={() => { */}
                  
                  { !modal &&
                
                  <Button disabled={disabled} onClick={() => {
                    
                      saveProjectVersion({
                        variables: {
                          projectVersionId: projectVersion?.id,
                          data: getProjectVersionDataToSave(dataProject?.data)
                        },
                        update: (proxy, {data: {result}}) => {
                          const data = proxy.readQuery({
                            query: GET_PROJECT_VERSION,
                            variables: {projectId, projectVersionId: projectVersion.id},
                          });
                          proxy.writeQuery({
                            query: GET_PROJECT_VERSION,
                            variables: {
                              projectId: projectId,
                              projectVersionId: projectVersion.id
                            },
                            data: {
                              ...data,
                              result: isUpdate ? data.result : [...data.result, result]
                            }
                          });
                        }
                      })
                      .then((result) => {
                        
                        toggleModal(false);
                        setModal(false);
                        
                      })
                      .catch((error) => {
                        
                        setErrorMessage(error.message);
                        toggle();
                        
                      });
                      //toggleModal(false)
                  }}>{translate("submitProjectVersion" )}</Button>
                  
                  }
                  
                  { modal &&
                  
                  <Button onClick={() => {
                    
                    setModal(false);
                    setErrorMessage("");
                    toggleModal(false);

                  }}>{"Annulla"}</Button>
                  
                  }
                  
                  { modal &&
                    
                  (<>
                    
                    <div>
                      
                      <br/>
                      <br/>
                    
                    </div>
                    <div>
                      
                      { errorMessage }
                    
                    </div>
                  
                  </>)
                  
                  }
                  
                </ModalFooter>
                  
                </>}
              
            </Modal>
        </div>
    )
};
export default ProjectVersionModal;
